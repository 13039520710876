import React, { useEffect, useMemo, useState, lazy, Suspense } from "react"
import isEqual from 'react-fast-compare'
import { graphql } from "gatsby"

import { trackEvent } from "components/analytics"
import usePageLoading from 'components/utils/usePageLoading'
import useIFrameResizer from "components/utils/useIFrameResizer"

import { dataStore, filterStore, pageStore } from "state/store-zustand"
import useContextTheme from 'components/utils/useContextTheme'

import Grid from '@mui/material/Grid'
import SEO from "components/seo"
import Header from "components/header"
import Footer from "components/footer"
import SnackMessage from "components/elements/messages/snackbar"


const AuthDialog = lazy(() => import("components/account/authDialog"))
const EditDialog = React.memo(lazy(() => import("components/forms/editDialog")));
const Filters = lazy(() => import("components/filters/Filters"))
const ImpactArea = lazy(() => import("components/sections/impactArea"))
const EventsBlock = lazy(() => import("components/post/blocks/eventsBlock"))

import { eventsToSchema } from "components/utils/schemaUtils.js"
import { fetchPlacesDetails } from "components/utils/testHelpers.js"

import "styles/pages.scss"
import "styles/map_page.scss"

const EventsIndex = React.memo(props => {
  //console.log('EventsIndex ', pageContext, pageData)
  const { activity = "events", city, data, pageContext, showDesc = false } = props
  const { slug, events, eventsRecurring } = pageContext

  // FIXME: the page shoudln't have to call this hook, but it does
  const themeContext = useContextTheme()

  // TODO: Set form context
  //const currentActivity = activities.filter(item => item.slug === activity)[0]
  const currentActivity = undefined

  const initialEvents = useMemo(() => events && events.length > 0 ? events : [], [events])
  const allEvents = initialEvents.concat(eventsRecurring)

  trackEvent("events_page", "Events", props.location.href)

  const initialSchemaData =
    allEvents && allEvents.length > 0 ? eventsToSchema(allEvents) : []

  const [schemaData, setSchemaData] = useState(
    initialSchemaData ? initialSchemaData : null
  )

  const pageLoader = usePageLoading()


  const [hasResizer, parentIFrame] = useIFrameResizer()
  if (hasResizer) {
    window.parentIframe = parentIFrame
  }

  const cardLayout = pageStore((state) => state.cardLayout)
  const eventLayout = pageStore((state) => state.eventLayout)
  const compact = pageStore((state) => state.compact)
  const embedded = pageStore((state) => state.embedded)
  const isClient = pageStore((state) => state.isClient)
  const isMobile = pageStore((state) => state.isMobile)
  const showLogin = pageStore((state) => state.showLogin)
  const message = pageStore((state) => state.message)
  const showMessage = pageStore((state) => state.showMessage)
  const setAndShowMessage = pageStore((state) => state.setAndShowMessage)
  const themeClass = pageStore((state) => state.themeClass)
  const themeObject = pageStore((state) => state.themeObject)

  const eventCards = dataStore((state) => state.eventCards)
  const eventID = dataStore((state) => state.eventID)
  const eventCurrent = dataStore((state) => state.eventCurrent)
  const showEditForm = dataStore((state) => state.showEditForm)
  const setEventCurrent = dataStore((state) => state.setEventCurrent)
  const eventCurrentIndex = dataStore((state) => state.eventCurrentIndex)
  const setEventCurrentIndex = dataStore((state) => state.setEventCurrentIndex)
  const setShowEditForm = dataStore((state) => state.setShowEditForm)
  const setEventsShouldRefresh = dataStore((state) => state.setEventsShouldRefresh)

  const [isLoading, setIsLoading] = useState()


  const handleShowEditForm = (show, id) => {
    if (id == null) {
      setEventCurrent(null)
    }

    setShowEditForm(show);
  }

  const handleEditSubmitted = (data) => {
    console.log('handleEditSubmitted ', data)
    const { name } = data

    if (name) {
      setAndShowMessage('Event updated successfully!')
      setEventCurrent(null)
      setShowEditForm(false)

      setTimeout(() => {
        setEventsShouldRefresh(true)
      }, 2000)
      // TODO: trigger a refresh of the event cards
    }

  }


  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true)
      console.log('fetchData ', id, eventID);
      const details = await fetchPlacesDetails(id, 'event', true)
        .catch(e => {
          console.log(`Error with event `, id, e)
        })

      if (details) {
        setEventCurrent(details.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    }

    const shouldFetch = eventID && !eventCurrent || eventID != eventCurrent?.id
    if (shouldFetch) {
      fetchData(eventID)
    }

  }, [eventID])


  // When editing, allow the user to go to the next card or back
  const handleNextCard = (index) => {
    setEventCurrentIndex(index)
  }

  const isFeatured = filterStore((state) => state.isFeatured)
  const showCats = filterStore((state) => state.showCats)
  const setShowCats = filterStore((state) => state.setShowCats)

  const addEventLink = themeObject?.eventForm?.link || '/add-event'

  useEffect(() => {
    setShowCats(false)
  }, [])

  // If event cards change, update schema
  useEffect(() => {
    if (eventCards && eventCards.length > 0) {
      const schema = eventsToSchema(eventCards)
      setSchemaData(schema)
    }
  }, [eventCards])


  const node = data.allWpPage.edges[0]?.node
  const description = data?.allWpPage?.edges[0]?.node?.seo?.metaDesc

  const today = new Date()
  const year = today.getFullYear()
  const month = today.toLocaleString("default", { month: "short" })

  // Unique events handles for Events page
  const onChange = newFilters => {
    console.log("onChange ", newFilters)
    //setFilters(newFilters)
    //setActivity(filters.activities[0])
    const selectedActivity = newFilters.activities[0]
    // TODO: Implement reusable method for this route(s)
    const path =
      selectedCity == "all"
        ? `/events/${selectedActivity}`
        : `/cities/${selectedCity}/events/${selectedActivity}`

    console.log(`Navigate to path `, path)
    //navigate(path)
  }

  // TODO: Set SEO
  // Calendar of Events and Things to Do <today; this week> <month year> in <city>
  // Eventbrite: Vancouver, Canada Events Today | Eventbrite
  const titleBase = `Events & Things to Do`
  let pageNode = data.allWpPage.edges[0]?.node
    ? data.allWpPage.edges[0].node
    : {
      impactArea: {
        heading: titleBase,
      }
    }

  // Memoize selectedCity
  const selectedCity = useMemo(() => pageContext.cityDetails, [pageContext.cityDetails])

  // TODO: Make the name/title lookup reusable
  // TODO: CONTEXT: Move to Location context
  const displayCity = selectedCity
    ? selectedCity?.name
      ? selectedCity.name
      : selectedCity.title
    : "your city"

  const predicate = `${currentActivity?.title ? currentActivity.title + " " : ""}`
  const title =
    `${predicate}` +
    `Events & Things to Do ${selectedCity ? "in " + displayCity : "near you" } |` +
    ` ${month}, ${year} | Vibemap`
  pageNode.impactArea.heading = `${predicate}${pageNode.title}`
  const metaDesc = `${pageNode.impactArea.heading} : Check out the Vibemap calendar of events and things to do ${selectedCity ? "in " + selectedCity.name : "near you"}.`

  const seoData = useMemo(() => {
    return {
      title: title,
      metaDesc: metaDesc,
      schemaType: "WebPage",
    };
  }, [title, metaDesc]);

  const section_key = "event-block"
  const pageClass = `page events ${pageLoader} ${embedded ? 'embedded' : ''} ${themeClass ? themeClass : ''} ${compact ? 'compact' : ''}`

  if (!isClient) {
    return null
  }

  // TODO: This could be set in Zustand Global State
  const numCols = themeClass == "theme-union_square"
    ? 4
    : compact
      ? 1
      : 3

  const filterCols = !isMobile && eventLayout == "columns" ? 3.5 : 12
  const cardsCols = !isMobile && eventLayout == "columns" ? 8.5 : 12
  const showDateCalendar = !isMobile && eventLayout == "columns" ? true : false

  const mainStyles = {
    margin: "0 auto",
    maxWidth: compact ? "800px" : "100%",
  }

  const containerClass = hasResizer
    ? `container container-resizable`
    : `container`

  // Don't show login on featured carousel
  const showAuth = isFeatured == false && showLogin

  return (
    <div className={pageClass}>
      <SEO
        canonical={slug}
        description={seoData.metaDesc}
        lang="en-US"
        title={title}
        data={seoData}
        schemaData={schemaData}
        />

      <SnackMessage open={showMessage} message={message} useScrollPosition={embedded} />

      <main style={mainStyles}>
        {embedded
          ? null
          : <Header />
        }

        {showAuth
          ? <Suspense fallback={null}>
              <AuthDialog />
            </Suspense>
          : null
        }

        <Suspense fallback={null}>
          <EditDialog
            isMobile
            isOpen={showEditForm}
            handleCloseCallback={() => handleShowEditForm(false, null)}
            handleSubmitCallback={handleEditSubmitted}
            handleNextCard={handleNextCard}
            eventCurrentIndex={eventCurrentIndex}
            eventCurrent={eventCurrent}
            setOpen={setShowEditForm}
            />
        </Suspense>

        {embedded
          ? null
          : <Suspense fallback={null}>
              <ImpactArea data={pageNode} />
            </Suspense>
        }

        <div className="post-body">
          <Grid
            container
            //direction={eventLayout} // "rows" or "columns"
            spacing={0}
            >

            <Grid item xs={filterCols} >
              <Filters
                activity={activity}
                page={"events"}
                size={"small"}
                layout={eventLayout}
                showDateCalendar={showDateCalendar}
                showEventSearch={true}
                showIcons={false}
                showVibes={true}
                showTags={true}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={cardsCols}>
              <section className="body">
                {showDesc && description && (
                  <div className={containerClass}>
                    <div className="narrow description">
                      <p>{description}</p>
                    </div>
                  </div>
                )}

                <section className="post-container" key={section_key}>
                  <EventsBlock
                    columns={numCols}
                    style={cardLayout}
                    eventCurrentIndex={eventCurrentIndex}
                    addEventLink={addEventLink} />
                </section>
              </section>
            </Grid>
          </Grid>
        </div>
      </main>
      {embedded
        ? null
        : <Footer />
      }
    </div>
  )
}, (prevProps, nextProps) => {
  console.log('EventsIndex: shouldComponentUpdate ', prevProps, nextProps)
  return isEqual(prevProps, nextProps)
})

export const eventsPageQuery = graphql`
  fragment eventpageDetailsFields on WpPage_Pagedetails {
    vibes {
      contentNodes {
        nodes {
          slug
          ... on WpActivity {
            id
            seo {
              focuskw
              metaDesc
            }
            title
          }
        }
      }
    }
    vibeset {
      ... on WpVibeset {
        id
        slug
        wpChildren {
          nodes {
            slug
          }
        }
      }
    }
  }

  fragment seoFields on WpPostTypeSEO {
    focuskw
    metaDesc
    title
  }

  query EventsTemplateQuery {
    allWpPage(filter: { slug: { eq: "events-template" } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          pageDetails {
            ...eventpageDetailsFields
          }
          title
          seo {
            ...seoFields
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
// Add why did you render
EventsIndex.whyDidYouRender = true
export default EventsIndex